<template>
    <div class="firstBox">
        <div class="itemBox">
            <div class="title">微信小程序名称：</div>
            <div class="content" style="color: #999">{{ dataInfo.name }}</div>
        </div>
        <!-- <div class="itemBox">
      <div class="title">微信小程序账号：</div>
      <div class="content">
        <el-input v-model="account"></el-input>
        <img src="@/assets/image/isUpdate.png" alt="" />
      </div>
    </div> -->
        <div class="itemBox">
            <div class="title">原始ID：</div>
            <div class="content" style="color: #999">{{ dataInfo.original }}</div>
        </div>
        <div class="itemBox">
            <div class="title">微信小程序APPID：</div>
            <div class="content" style="color: #999">{{ dataInfo.key }}</div>
        </div>
        <div class="itemBox">
            <div class="title">APPSecret:</div>
            <div class="content">
                <div class="inBox">
                    <el-input :spellcheck="false" ref="inputMy" v-model="copyMy"></el-input>
                    <img src="@/assets/image/isUpdate.png" alt="" />
                </div>
            </div>
        </div>
        <div class="itemBox">
            <div class="title">版本号：</div>
            <div class="content" style="color: #999">
                {{ dataInfo.version_online == '' ? '暂无已发布的线上版本' : dataInfo.version_online }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dataInfo: {
            type: Object,
            default: () => {
                return {
                    name: '',
                    // account: '',
                    original: '',
                    key: '',
                    secret: '',
                    version: '0.0',
                };
            },
        },
         storeId: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            isUpdate: false,
            copyMy: '',
        };
    },
    methods: {
        // 保存信息
        preservation () {
            let that = this;
            if (!this.copyMy) {
                this.$message({
                    message: '请将信息配置完整',
                    type: 'warning',
                });
                return;
            }
            let path = this.$api.set.setWxapp
            let obj = {
                secret: this.copyMy,
            }
            if (this.storeId) {
                path = this.$api.serviceProvider.setWxapp
                obj.store_id = this.storeId
            }
            this.$axios
                .post(path, obj)
                .then(res => {
                    if (res.code == 0) {
                        this.$emit('updateBasicsSu', that.copyMy);
                        that.$message({
                            message: '保存成功',
                            type: 'success',
                        });
                    } else {
                        that.$message({
                            message: '修改成功',
                            type: 'error',
                        });
                    }
                });
        },
    },
};
</script>

<style lang="less" scoped>
.itemBox {
    display: flex;
    align-items: center;

    .title {
        width: 150px;
        text-align: right;
        white-space: nowrap;
        flex-shrink: 0;
        color: #818181;
    }

    .content {
        width: calc(100% - 160px);
        flex-shrink: 0;
        padding: 25px 0 23px 0;
        font-size: 14px;
        color: #333;
        border-bottom: 1px solid #ebebeb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 10px;

        .inBox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .el-input {
            width: 90%;
            line-height: normal !important;

            /deep/ input {
                line-height: normal !important;
                letter-spacing: 1px;
                border: 1px solid transparent;
                padding: 0;
                height: inherit;
            }
        }

        .btn {
            color: #00ca5a;
            cursor: pointer;
            user-select: none;
            margin-left: 10px;
        }
    }
}
</style>
